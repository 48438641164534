<template>
  <div class="container">
    <!-- 页头 -->
    <content-title :nav="nav"></content-title>
    <!-- 状态筛选 -->

    <!-- 宝宝秀列表 -->
      <div class="sec-title">宝宝秀审核</div>
    <div style="margin-bottom:1rem">
      <el-select
        v-model="value"
        @change="remoteMethod(value)"
        placeholder="请选择状态"
        size="small"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column align="center" prop="id" label="序号"> </el-table-column>
      <el-table-column
        align="center"
        width="320"
        prop="content"
        label="发布内容"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="map.userinfo.nick_name"
        label="发布者"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="map.masterSetPriceEntity.title"
        label="推荐的课程"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="map.mechanism_name"
        label="所属机构"
      >
      </el-table-column>
      <el-table-column align="center" prop="share_count" label="分享数">
      </el-table-column>
      <el-table-column align="center" prop="comment_count" label="评论数">
        <template slot-scope="scope">
          <span @click="comments(scope.row.id)">{{
            scope.row.comment_count
          }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="collect_count" label="点赞数">
      </el-table-column>
      <!-- :filters="[
            { text: '完全公开', value: '1' },
            { text: '仅好友可见', value: '2' },
            { text: '审核中', value: '3' },
            { text: '违规', value: '4' },
          ]"
          :filter-method="filterTag"
          filter-placement="bottom-end" -->
      <el-table-column prop="status" label="状态" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 1" style="color: #85ce61"
            >完全公开</span
          >
          <span v-else-if="scope.row.status == 2" style="color: #85ce61"
            >仅好友可见</span
          >
          <span v-else-if="scope.row.status == 3" style="color: #66b1ff"
            >审核中</span
          >
          <span v-else-if="scope.row.status == 4" style="color: #a6a9ad"
            >违规</span
          >
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="300px">
        <template slot-scope="scope">
          <div>
            <el-button type="danger" @click="updata(scope.row.id, 4)"
              >拒绝</el-button
            >
            <el-button type="primary" @click="updata(scope.row.id, 1)"
              >通过</el-button
            >
            <el-button type="primary" @click="updata(scope.row.id, 5)"
              >永久禁言</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="10"
      layout="prev, pager, next, jumper"
      :total="totalNumber"
      class="pagezzss"
    >
      <div></div
    ></el-pagination>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
export default {
  name: "BabyShowAudit",
  //信息审核
  data() {
    return {
      nav: {
        firstNav: "内容管理",
        secondNav: "宝宝秀"
      },
      currentPage: 1,
      totalNumber: 0,
      tableData: [],
      value: 1,
      show: 1,
      options: [
        {
          value: 1,
          label: "完全公开"
        },
        {
          value: 2,
          label: "仅好友可见"
        },
        {
          value: 3,
          label: "审核中"
        },
        {
          value: 4,
          label: "违规"
        }
      ]
    };
  },
  mounted() {
    this.GetEvaluation();
  },
  methods: {
    GetEvaluation() {
      let url = "/message/noteUser/queryManagerListPage";
      let par = {
        is_teach_paypal: true,
        pageSize: 10,
        currentPage: this.currentPage,
        status: this.value
      };
      this.$axios
        .get(url, {
          params: par
        })
        .then(res => {
          console.log("获取列表", res.data.data.rows);
          this.tableData = res.data.data.rows;
          this.totalNumber = res.data.data.total;
        });
    },
    updata(id, status) {
      let url = "/message/noteUser/update";
      let data = {
        id,
        status
      };
      this.$axios.post(url, data).then(res => {
        this.GetEvaluation();
        this.$message({
          message: "成功",
          type: "success"
        });
      });
    },
    comments(e) {
      console.log("xxx", e);
      this.$router.push({
        name: "InformationReview",
        query: {
          userID: e
        }
      });
    },
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.currentPage = val;
      this.GetEvaluation();
    },
    remoteMethod() {
      this.GetEvaluation();
    },
    filterTag(value, row) {
      if (this.show == 10) {
        console.log(row.status, value);
        this.show = 1;
      }
      this.show += 1;
      return row.status == value;
    }
  }
};
</script>

<style></style>
